import React from 'react'
import { Badge } from 'reactstrap'

const OrderStatus = ({ order }) => {
  if (order.status === 'PLACED') {
    return (
      <Badge pill className="vates-warning">
        Order in progress
      </Badge>
    )
  }
  if (order.status === 'CLOSED') {
    return (
      <Badge pill className="vates-secondary">
        Closed
      </Badge>
    )
  }

  /*
   * Credit card status
   */
  if (order.status === 'CARD_EXPECTED') {
    return (
      <Badge pill className="vates-warning">
        Order in progress
      </Badge>
    )
  }
  if (
    order.status === 'CARD_ONESHOT_PAYMENT_PENDING' ||
    order.status === 'CARD_RECURRING_PAYMENT_PENDING'
  ) {
    return (
      <Badge pill className="vates-warning">
        Order in progress
      </Badge>
    )
  }
  if (order.status === 'UP') {
    return (
      <Badge pill className="vates-success">
        Subscribed {+order.paymentModel.year > 0 ? 'Yearly' : 'Monthly'}
      </Badge>
    )
  }

  /*
   * Transfer status
   */
  if (order.status === 'CANCELLED') {
    return (
      <Badge pill className="vates-secondary">
        Cancelled
      </Badge>
    )
  }
  if (order.status === 'TRANSFER_EXPECTED') {
    if (order.transferProofs.length > 0) {
      return (
        <Badge pill className="vates-info">
          We are processing your order
        </Badge>
      )
    }

    if (order.purchaseOrders.length > 0 && order.invoices.length === 0) {
      return (
        <Badge pill className="vates-info">
          We are processing your order
        </Badge>
      )
    }

    if (order.signedQuotes.length > 0 && order.proformas.length === 0) {
      return (
        <Badge pill className="vates-info">
          We are processing your order
        </Badge>
      )
    }

    if (order.proformas.length > 0 && order.purchaseOrders.length === 0) {
      return (
        <Badge pill className="vates-warning">
          Order in progress
        </Badge>
      )
    }

    return (
      <Badge pill className="vates-warning">
        Order in progress
      </Badge>
    )
  }

  return order.status
}

export default OrderStatus
