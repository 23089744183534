import React from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
import { injectState, provideState } from 'reaclette'
import { HandledErrors, AuthenticationFailed } from '@vates/xw-api-errors'

import { getApi } from '../../api'
import ActionButton from '../components/action-button'

const initState = { password: '', newEmail: '' }

const withState = provideState({
  initialState: () => ({ ...initState }),
  effects: {
    async handleInputChange(_, { target }) {
      this.state[target.name] = target.value
    },
    resetState: () => ({ ...initState }),
    async updateEmail(effects, event) {
      event.preventDefault()
      try {
        try {
          const account = await getApi().changeEmail(
            this.props.customerId,
            this.state.password,
            this.state.account.email,
            this.state.newEmail
          )
          await effects.notify('success', 'Email successfully updated')
          await this.props.updateData(account)
          effects.resetState()
        } catch (error) {
          if (error instanceof AuthenticationFailed) {
            throw new HandledErrors('Invalid password')
          }
          throw error
        }
      } catch (error) {
        effects.handleError(error)
      }
    },
  },
})

const ChangeEmail = ({ effects, state }) => (
  <Card>
    <CardHeader tag="h5">Change Email</CardHeader>
    <CardBody>
      <Form id="change-email-form">
        <Row form>
          <Col md="6">
            <FormGroup>
              <Label for="password">Current password*</Label>
              <Input
                name="password"
                id="password"
                onChange={effects.handleInputChange}
                required
                type="password"
                value={state.password}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label for="newEmail">New Email*</Label>
              <Input
                name="newEmail"
                id="newEmail"
                onChange={effects.handleInputChange}
                required
                type="text"
                value={state.newEmail}
              />
            </FormGroup>
          </Col>
        </Row>
        <ActionButton
          classNames="float-right"
          color="success"
          form="change-email-form"
          text="Update Email"
          action={effects.updateEmail}
        />
      </Form>
    </CardBody>
  </Card>
)

export default withState(injectState(ChangeEmail))
