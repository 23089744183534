import React, { useEffect, useState } from 'react'
import {
  Pagination as PaginationWrapper,
  PaginationItem,
  PaginationLink,
} from 'reactstrap'

const Pagination = ({ totalElements, onChangePage, perPage = 15 }) => {
  const [pagination, setPagination] = useState([])
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    const totalPages = Math.ceil(totalElements / perPage)
    setPagination([...Array(totalPages).keys()].map((i) => i + 1))
  }, [totalElements, perPage])

  const handlePageChange = (page) => {
    setCurrentPage(page)
    onChangePage(page, perPage)
  }

  if (pagination.length <= 1) {
    return null
  }

  return (
    <PaginationWrapper aria-label="Page navigation">
      <PaginationItem disabled={currentPage === 1}>
        <PaginationLink
          previous
          onClick={() => handlePageChange(currentPage - 1)}
        />
      </PaginationItem>
      {pagination.map((page) => (
        <PaginationItem active={page === currentPage} key={page}>
          <PaginationLink
            onClick={() => handlePageChange(page)}
            disabled={currentPage === page}
          >
            {page}
          </PaginationLink>
        </PaginationItem>
      ))}
      <PaginationItem disabled={currentPage === pagination.length}>
        <PaginationLink
          next
          onClick={() => handlePageChange(currentPage + 1)}
        />
      </PaginationItem>
    </PaginationWrapper>
  )
}

export default Pagination
