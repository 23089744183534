import Price from 'format-price'
import React from 'react'
import dayjs from 'dayjs'
import { Badge, Col, Row, Table } from 'reactstrap'
import { FaRegFilePdf } from 'react-icons/fa'
import { injectState, provideState } from 'reaclette'
import { isEmpty } from 'lodash'
import { invoiceTotalDue } from '@vates/www-xo-utils'

import { getApi } from '../../api'
import Loader from '../components/loader'
import Pagination from '../components/pagination'

const INVOICES_PER_PAGE = 15

const withState = provideState({
  initialState: () => ({
    invoices: [],
    displayedInvoices: [],
    loading: false,
  }),
  effects: {
    async initialize(effects) {
      this.state.loading = true
      try {
        const { invoices } = await getApi().getInvoices(this.props.customerId)
        this.state.invoices = invoices.reverse()
        effects.handlePageChange(1, INVOICES_PER_PAGE)
      } catch (error) {
        effects.handleError(error)
      } finally {
        this.state.loading = false
      }
    },
    handlePageChange(_, page, elementPerPage) {
      this.state.displayedInvoices = this.state.invoices.slice(
        elementPerPage * (page - 1),
        elementPerPage * page
      )
    },
  },
})

const InvoiceStatus = ({ invoice }) => {
  if (!invoice.data) {
    return null
  }
  if (invoice.data.cancelled) {
    return <Badge color="secondary">Cancelled</Badge>
  }
  if (invoice.data.unpaid) {
    return <Badge color="secondary">Awaiting payment</Badge>
  }

  return <Badge color="light">Paid</Badge>
}

const TotalAmount = ({ invoice }) => {
  const amount = invoiceTotalDue(invoice)
  return Price.format('en-US', invoice.data.currency, amount)
}

const Invoices = ({ effects, state }) => (
  <div style={{ margin: '20px' }}>
    <Row>
      <Col>
        <h3>Invoices</h3>
      </Col>
    </Row>
    <hr />
    <br />
    <Row>
      <Col>
        {state.loading ? (
          <Loader />
        ) : isEmpty(state.invoices) ? (
          'No invoices yet!'
        ) : (
          <Table>
            <thead>
              <tr>
                <th>Number</th>
                <th>Date</th>
                <th>Status</th>
                <th>Payment</th>
                <th>Amount</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>
              {state.displayedInvoices.map((invoice) => (
                <tr key={invoice.key}>
                  <td>{invoice.data.number}</td>
                  <td>{dayjs(invoice.date).format('MMM D, YYYY h:mm:ss A')}</td>
                  <td>
                    <InvoiceStatus invoice={invoice} />
                  </td>
                  <td>{invoice.data.method}</td>
                  <td>
                    <TotalAmount invoice={invoice} />
                  </td>
                  <td>
                    <a
                      href={`${window.location.protocol}//${window.location.host}/downloads/${invoice.token}?terms=true`}
                    >
                      <FaRegFilePdf size="20" />
                      {invoice.data.number}
                      .pdf
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Col>
    </Row>
    <Pagination
      perPage={INVOICES_PER_PAGE}
      onChangePage={effects.handlePageChange}
      totalElements={state.invoices.length}
    />
  </div>
)

export default withState(injectState(Invoices))
