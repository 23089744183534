import React from 'react'
import PasswordChecklist from './password-checklist'
import {
  FormGroup,
  Label,
  InputGroup,
  Input,
  InputGroupAddon,
  Button
} from 'reactstrap'
import { injectState, provideState } from 'reaclette'
import { FaEye, FaEyeSlash } from 'react-icons/fa'


const withState = provideState({
  initialState: () => ({  
    showPassword: false
  }),
  effects: {
    toggleVisibility() {
      this.state.showPassword = !this.state.showPassword
    },
  }
})

const PasswordInput = ({
  label,
  placeholder,
  name,
  password,
  onChange,
  state,
  effects,
}) => (
  <FormGroup>
    {label && <Label>{label}</Label>}
    <InputGroup>
      <Input
        name={name}
        type={state.showPassword ? 'text' : 'password'}
        placeholder={placeholder}
        onChange={onChange}
        value={password}
        required
      />
      <InputGroupAddon addonType="append">
        <Button color="secondary" onClick={effects.toggleVisibility}>
          {state.showPassword ? <FaEyeSlash /> : <FaEye />}
        </Button>
      </InputGroupAddon>
    </InputGroup>
    <PasswordChecklist password={password} />
  </FormGroup>
)

export default withState(injectState(PasswordInput))

