import {
  LENGTH_REGEX,
  LOWERCASE_REGEX,
  NUMBER_REGEX,
  SPECIAL_CHARS_REGEX,
  UPPERCASE_REGEX,
  isPassphrase,
} from '@vates/www-xo-utils'
import React from 'react'
import { FaCheck } from 'react-icons/fa'
import { Card, Collapse, Container } from 'reactstrap'

const RULES = [
  { label: 'At least one lowercase letter', pattern: LOWERCASE_REGEX },
  { label: 'At least one uppercase letter', pattern: UPPERCASE_REGEX },
  { label: 'At least one number', pattern: NUMBER_REGEX },
  { label: 'At least one special character', pattern: SPECIAL_CHARS_REGEX },
  { label: 'Minimum 8 characters', pattern: LENGTH_REGEX },
]

const PASSPHRASE = {
  label: 'Passphrase detected',
}

const PasswordChecklist = ({ password }) => (
  <Collapse isOpen={password.length > 0}>
    <Card style={{ marginTop: '8px' }}>
      <Container style={{ paddingTop: '15px', paddingBottom: '15px' }}>
        {isPassphrase(password) ? (
          <div key={PASSPHRASE.label} className="passed">
            <FaCheck /> {PASSPHRASE.label}
          </div>
        ) : (
          RULES.map((rule) => {
            const isValidPattern = password && password.match(rule.pattern)
            const className = isValidPattern ? 'passed' : ''

            return (
              <div
                key={rule.label}
                className={className}
                style={{ marginBottom: '4px' }}
              >
                {isValidPattern && <FaCheck />} {rule.label}
              </div>
            )
          })
        )}
      </Container>
    </Card>
  </Collapse>
)

export default PasswordChecklist
