import React, { Fragment } from 'react'
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
} from 'reactstrap'
import { injectState, provideState } from 'reaclette'

import { getApi } from '../../api'
import Loader from '../components/loader'
import ActionButton from '../components/action-button'
import RequestSupport from './request-support'

const withState = provideState({
  initialState: () => ({
    collaboratorEmail: '',
    supportUser: undefined,
    supportOrganization: undefined,
    supportMembers: undefined,
    suggestedOrganizationEmail: undefined,
    loading: false,
  }),
  effects: {
    async initialize(effects) {
      effects.loadContext()
    },
    async loadContext(_) {
      try {
        if (this.state.customerId) {
          this.state.loading = true
          const { user, organization, members, suggestedOrganizationEmail } =
            await getApi().getSupportContext(this.state.customerId)
          this.state.supportUser = user
          this.state.supportOrganization = organization
          this.state.supportMembers = members
          this.state.suggestedOrganizationEmail = suggestedOrganizationEmail
        }
      } catch (error) {
        if (error.name !== 'ResourceNotFound') {
          throw error
        }
      } finally {
        this.state.loading = false
      }
    },
    async handleSharedOrganizationChange(_, { target }) {
      this.state.supportOrganization = {
        ...this.state.supportOrganization,
        shared: target.checked,
      }
    },
    async handleInputChange(_, { target }) {
      this.state[target.name] = target.value
    },
    async saveSharedOrganization(effects, event) {
      event.preventDefault()
      try {
        await getApi().saveSharedOrganization(
          this.state.customerId,
          this.state.supportOrganization.id,
          this.state.supportOrganization.shared
        )
        effects.notify(
          'success',
          this.state.supportOrganization.shared
            ? 'Tickets are now shared among your organization'
            : 'Tickets are no longer shared among your organization'
        )
        effects.loadContext()
      } catch (error) {
        effects.handleError(error)
      }
    },
    async addCollaborator(effects, event) {
      event.preventDefault()
      try {
        await getApi().addCollaborator(
          this.state.customerId,
          this.state.collaboratorEmail
        )
        this.state.collaboratorEmail = ''
        effects.notify('success', 'Collaborator added to your organization')
        effects.loadContext()
      } catch (error) {
        effects.handleError(error)
      }
    },
    async removeCollaborator(effects, id) {
      try {
        await getApi().removeCollaborator(this.state.customerId, id)
        await effects.notify(
          'success',
          'Collaborator removed from your organization'
        )
        effects.loadContext()
      } catch (error) {
        effects.handleError(error)
      }
    },
  },
  computed: {
    hasSupport: (state) =>
      (state.support && state.support.zammadId) || state.supportUser,
  },
})

const Support = ({ effects, state }) => (
  <div style={{ margin: '20px' }}>
    <Row>
      <Col>
        <h3>Support</h3>
      </Col>
    </Row>
    <hr />

    {state.loading ? (
      <Loader />
    ) : state.hasSupport ? (
      <Fragment>
        <p>
          You have an account to access our{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={state.config.supportFrontEndUrl}
          >
            helpdesk
          </a>
          , use the same credentials as here.
        </p>
        {state.supportOrganization && (
          <Fragment>
            <p>
              You belong to the following organization:{' '}
              <span style={{ fontSize: '20px' }}>
                <Badge pill className="vates-secondary">
                  {state.supportOrganization.name}
                </Badge>
              </span>
            </p>
            {state.supportOrganization.organization_head_user ===
              state.supportUser.id.toString() && (
              <Fragment>
                <Card>
                  <CardHeader tag="h5">Support ticket</CardHeader>
                  <CardBody>
                    <Form id="share-form">
                      <Row>
                        <Col>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                style={{ transform: 'scale(1.5)' }}
                                checked={state.supportOrganization.shared}
                                onChange={
                                  effects.handleSharedOrganizationChange
                                }
                              />{' '}
                              Share support ticket management across your
                              Organization.
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <ActionButton
                        action={effects.saveSharedOrganization}
                        color="success"
                        form="share-form"
                        text="Save"
                        classNames="float-right"
                      />
                    </Form>
                  </CardBody>
                </Card>
                <br />
                <Card>
                  <CardHeader tag="h5">Collaborator</CardHeader>
                  <CardBody>
                    <div style={{ marginBottom: '1.25rem' }}>
                      To add a collaborator, the user should have an existing
                      xen-orchestra.com account, registered to the support
                      platform.
                      <br />
                      <a
                        href="https://help.vates.fr/kb/en-us/8-technical-support"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        See our dedicated article for more details.
                      </a>
                    </div>
                    {state.supportMembers &&
                      state.supportMembers
                        .filter((member) => member.id !== state.supportUser.id)
                        .map((member) => (
                          <InputGroup style={{ marginBottom: '10px' }}>
                            <Input disabled value={member.email} />
                            <InputGroupAddon addonType="append">
                              <Button
                                color="danger"
                                onClick={() =>
                                  effects.confirm(
                                    effects.removeCollaborator,
                                    [member.id],
                                    {
                                      confirmText:
                                        'I want to remove this collaborator',
                                      closeText: 'Cancel',
                                    }
                                  )
                                }
                              >
                                Remove
                              </Button>
                            </InputGroupAddon>
                          </InputGroup>
                        ))}
                    <Form id="add-collaborator-form">
                      <InputGroup>
                        <Input
                          name="collaboratorEmail"
                          onChange={effects.handleInputChange}
                          placeholder="New collaborator Email"
                          required
                          type="email"
                          value={state.collaboratorEmail}
                        />
                        <InputGroupAddon addonType="append">
                          <ActionButton
                            color="success"
                            form="add-collaborator-form"
                            text="Add Collaborator"
                            action={effects.addCollaborator}
                          />
                        </InputGroupAddon>
                      </InputGroup>
                    </Form>
                  </CardBody>
                </Card>
              </Fragment>
            )}
          </Fragment>
        )}
        {!state.supportOrganization && state.suggestedOrganizationEmail && (
          <Fragment>
            Relying on your company name, we guess you may be part of a shared
            organization among our helpdesk users. If you wish to join this
            organization to share and cooperate on support tickets, please
            contact {state.suggestedOrganizationEmail} for an add request.
          </Fragment>
        )}
      </Fragment>
    ) : (
      <RequestSupport />
    )}
  </div>
)

export default withState(injectState(Support))
