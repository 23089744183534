import { request } from './fetch-api'

export const getAccount = token => request('/api', 'getAccount', { token })

export const getClientConf = configurations =>
  request('/api', 'getClientConf', { configurations })

export const activateAccount = (jwt, password) =>
  request('/api', 'activateAccount', { jwt, password })

export const authenticate = (email, password) =>
  request('/api', 'authenticate', { email, password })

export const deleteAccount = xwToken =>
  request('/api', 'deleteAccount', { xwToken })

export const updateAccountInfo = (xwToken, billingInfo) =>
  request('/api', 'updateAccountInfo', { xwToken, billingInfo })

export const verifyVatNumber = (xwToken, countryCode, vatInComNumber) =>
  request('/api', 'verifyVatNumber', { xwToken, countryCode, vatInComNumber })

export const createAccount = (email, company) =>
  request('/api', 'createAccount', { email, company })

export const criticalErrorFeedback = (xwToken, error, info) =>
  request('/api', 'criticalErrorFeedback', { xwToken, error, info })

// @deprecated
export const addInvoiceEmail = (xwToken, email) =>
  request('/api', 'addInvoiceEmail', { xwToken, email })

// @deprecated
export const removeInvoiceEmail = (xwToken, email) =>
  request('/api', 'removeInvoiceEmail', { xwToken, email })

export const updateNotifications = (xwToken, changes) =>
  request('/api', 'updateNotifications', { xwToken, changes })

export const savePreferences = (xwToken, preferences) =>
  request('/api', 'savePreferences', { xwToken, preferences })

export const saveAccountOrigin = (xwToken, origin) =>
  request('/api', 'saveAccountOrigin', { xwToken, origin })

export const addStripeCard = (xwToken, cardToken) =>
  request('/api', 'addStripeCard', { xwToken, cardToken })

export const removeStripeCard = (xwToken, id) =>
  request('/api', 'removeStripeCard', { xwToken, id })

export const replaceStripeCard = (xwToken, cardToken) =>
  request('/api', 'replaceStripeCard', { xwToken, cardToken })

export const changeEmail = (xwToken, password, oldEmail, newEmail) =>
  request('/api', 'changeEmail', { xwToken, password, oldEmail, newEmail })

export const changePassword = (xwToken, currentPassword, newPassword) =>
  request('/api', 'changePassword', { xwToken, currentPassword, newPassword })

export const passwordLost = email => request('/api', 'passwordLost', { email })

export const setPasswordFromRecovery = (jwt, password) =>
  request('/api', 'setPasswordFromRecovery', { jwt, password })

export const unsubscribe = token => request('/api', 'unsubscribe', { token })

export const getInvoices = xwToken =>
  request('/api', 'getInvoices', { xwToken })

export const getSupportContext = xwToken =>
  request('/api', 'getSupportContext', { xwToken })

export const addCollaborator = (xwToken, collaboratorEmail) =>
  request('/api', 'addCollaborator', { xwToken, collaboratorEmail })

export const removeCollaborator = (xwToken, collaboratorId) =>
  request('/api', 'removeCollaborator', { xwToken, collaboratorId })

export const saveSharedOrganization = (
  xwToken,
  organizationId,
  sharedOrganization
) =>
  request('/api', 'saveSharedOrganization', {
    xwToken,
    organizationId,
    sharedOrganization
  })

export const requestRole = (xwToken, info) =>
  request('/api', 'requestRole', {
    xwToken,
    role: 'reseller',
    info,
    bypassActiveCheck: true
  })

export const cancelRole = (xwToken, role) =>
  request('/api', 'cancelRole', { xwToken, role })

export const getUserEmail = (customerId) =>
  request('/api', 'getUserEmail', { customerId })

export const requestSupportStart = (xwToken, password) =>
  request('/api', 'requestSupportStart', { xwToken, password })

export const requestPartnerPortalStart = (xwToken, password) =>
  request('/api', 'requestPartnerPortalStart', { xwToken, password })
