import React from 'react'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap'
import { injectState, provideState } from 'reaclette'

import { getApi } from '../../api'

const withState = provideState({
  initialState: () => ({
    modal: false
  }),
  effects: {
    toggleModal() {
      this.state.modal = !this.state.modal
    },
    async deleteAccount(effects) {
      try {
        await getApi().deleteAccount(this.props.customerId)
        effects.notify('success', 'Account successfully deleted')
        this.props.logOut()
      } catch (error) {
        effects.handleError(error)
      }
    }
  }
})

const AccountDelete = ({ effects, state }) => (
  <Card>
    <CardHeader tag="h5">Delete account</CardHeader>
    <CardBody>
      <Row>
        <Col>You can delete your account by clicking on this button</Col>
      </Row>
      <br />
      <Button
        onClick={effects.toggleModal}
        color="danger"
        className="float-right"
      >
        Delete my account
      </Button>
      <Modal isOpen={state.modal} toggle={effects.toggleModal}>
        <ModalHeader toggle={effects.toggleModal}>Delete account</ModalHeader>
        <ModalBody>By deleting your account, all of your personal data will be
          permanently removed, including any subscriptions to our newsletter,
          which you will no longer receive. Please note that account deletion is
          only possible if there are no active subscriptions or invoices that
          must be retained for legal reasons.</ModalBody>
        <ModalFooter>
          <Button outline onClick={effects.toggleModal}>
            Cancel
          </Button>
          <Button color="danger" onClick={effects.deleteAccount}>
            Delete my account
          </Button>
        </ModalFooter>
      </Modal>
    </CardBody>
  </Card>
)

export default withState(injectState(AccountDelete))
