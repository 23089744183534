import { FaEnvelope, FaPlus, FaTrash } from 'react-icons/fa'
import React from 'react'
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap'
import { injectState, provideState } from 'reaclette'

import { NOTIFICATIONS } from '@vates/www-xo-utils'
import { getApi } from '../../api'
import { find, includes, isEmpty, pull, remove } from 'lodash'

/**
 * Represents the collection of changes to be applied to user's additional email recipients notification.
 * Each item in the array tracks modifications for a specific user's notification tags.
 * 
 * Structure of each item:
 * {
 *   email: string,    // The email of the additional email recipient
 *   add: string[],    // Tags to be added for this recipient
 *   remove: string[], // Tags to be removed for this recipient
 *   added: boolean,   // Flag indicating if this recipient has been newly added
 *   deleted: boolean  // Flag indicating if this recipient should be deleted
 * }
 */
const changes = []

function addTag (email, tag) {
  let change = changes.find(c => c.email === email)
  if (!change) {
    change = {
      email
    }
    changes.push(change)
  }
  if (includes(change.remove, tag)) {
    pull(change.remove, tag)
    if (isEmpty(change.remove)) {
      delete change.remove
    }
  } else {
    const add = change.add || (change.add = [])
    add.push(tag)
  }

}

function removeTag (email, tag) {
  let change = changes.find(c => c.email === email)
  if (!change) {
    change = {
      email
    }
    changes.push(change)
  }
  if (includes(change.add, tag)) {
    pull(change.add, tag)
    if (isEmpty(change.add)) {
      delete change.add
    }
  } else {
    const remove = change.remove || (change.remove = [])
    remove.push(tag)
  }
}

function deleteChanges (email, checked) {
  let change
  if (checked) {
    change = changes.find(c => c.email === email)
    if (!change) {
      change = { 
        email
      }
      changes.push(change)
    }
    change.deleted = true
  } else {
    change = changes.find(c => c.email === email)
    
    if (isEmpty(change.add) &&
      isEmpty(change.remove) &&
      !change.added) {
      remove(changes, c => c === change)
    } else {
      delete change.deleted
    }
  }
  return change
}

function addChanges (email) {
  changes.push({email, added: true})
}

function hasChanges () {
  const hc = find(changes, ch => isRealChange(ch))
  return hc || false
}

function isRealChange(change) {
  const { added, deleted, add, remove: _remove } = change

  if (added && deleted) {
    return false
  }

  if (isEmpty(add)
    && isEmpty(_remove)
    && !added
    && !deleted) {
    return false
  }

  return true
}

function resetChanges () {
  changes.length = 0
}

const NCBState = provideState({
  initialState: (props) => ({
    on: props.checked
  }),
  effects: {
    handleCheckbox: (effects, { target: { checked } }) => (_, props) => {
      const { email, tag } = props
      if (checked) {
        addTag(email, tag)
      } else {
        removeTag(email, tag)
      }
      effects.setSaveButton(true)
      return {
        on: checked
      }
    }
  },
})

/**
 * 
 * @component
 * @param {string} email
 * @param {string} tag
 * @param {string} display
 * @param {boolean} disabled
 * @returns {JSX.Element}
 */
const NotificationCheckbox = ({ email, tag, display, disabled, effects, state }) => {
  return (
    <>
      <Input
        id={`${email}_${tag}_id`}
        name={`${email}_${tag}_name`}
        type="checkbox"
        onChange={effects.handleCheckbox}
        checked={state.on}
        disabled={disabled}
      />
      <Label check>{display}</Label>
    </>
  )
}
const NCB = NCBState(injectState(NotificationCheckbox))

const ARPState = provideState({
  initialState: () => ({
  }),
  effects: {}
})

/**
 * This component renders a panel for displaying an additional recipient's
 * email address and their associated notifications. It also provides a
 * feature to delete the recipient.
 * 
 * recipient = { email: "toto@toto.fr", tags: ['INVOICE', 'RENEWAL'] }
 * 
 * @component
 * @param {Object} recipient - A recipient object containing email and notifications
 */
const AdditionalRecipientPanel = ({ recipient}) => {
  const { email, notifications, disabled } = recipient
  return (
    <div
      className={disabled ? 'vates-secondary' : ''}
      key={email}
    >
      <Row>
        <Col>
          <FaEnvelope color="gray" />
          &nbsp;&nbsp;{email}
        </Col>
      </Row>
      <Collapse isOpen={true} className="mt-2">
        <Card className={disabled ? 'vates-secondary' : ''}>
          <CardBody>
            {Object.values(NOTIFICATIONS).map((notification) => (
              <Container key={`${email}_${notification.TAG}`}>
                <NCB
                  email={email}
                  tag={notification.TAG}
                  display={notification.DISPLAY}
                  checked={includes(notifications, notification.TAG)}
                  disabled={disabled}
                />
              </Container>
            ))}
          </CardBody>
        </Card>
      </Collapse>
    </div>
  )
}
const ARP = ARPState(injectState(AdditionalRecipientPanel))

/**
 * 
 * @component
 * @param {Object} change - A change object from the changes collection
 */
const ComfirmationSummary = ({ change }) => {

  if (!isRealChange(change)) {
    return null
  }

  return (
    <Row className="row-cols-auto mb-2">
      <Col md="auto">
        <b className="text-truncate">
          <FaEnvelope color="gray" />
          &nbsp;&nbsp;{change.email}:&nbsp;
        </b>
        <ul>
          {change.deleted ? 
            <li>Will be deleted from the additional email recipients</li> :
            <>
              {change.added && <li>Will be added to the additional email recipients</li>}
              {(change.add || []).map(tag => <li key={`${change.email}_${tag}_add`}><Badge className="confirm-notif-badge" color="success">+</Badge> {NOTIFICATIONS[tag].SHORT}</li>)}
              {(change.remove || []).map(tag => <li key={`${change.email}_${tag}_remove`}><Badge className="confirm-notif-badge" color="danger">-</Badge> {NOTIFICATIONS[tag].SHORT}</li>)}
            </>
          }
        </ul>
      </Col>
    </Row>
  )
}

const withState = provideState({
  initialState: () => ({
    email: '',
    removalModalOpen: false,
    recipients: [],
    saveDisabled: true
  }),
  effects: {
    initialize: (effects) => (_, props) => {
      effects.setRecipients(props.recipients)
      resetChanges()
    },
    setRecipients(_, recipients) {
      this.state.recipients = recipients
    },
    setSaveButton(_, enable) {
      this.state.saveDisabled = !enable
    },
    toggleModal() {
      this.state.removalModalOpen = !this.state.removalModalOpen
    },
    addRecipient: (effects, event) => (state) => {
      event.preventDefault()
      const email = event.target.elements.email.value
      const previous = state.recipients.find(
        recipient => recipient.email === email
      )

      if (previous) {
        effects.notify('warning', 'Mail is already present')
      } else {

        let { recipients } = state
        recipients = [...recipients, {email, notifications: []}]
        addChanges(email)
        effects.setSaveButton(true)

        event.target.elements.email.value = ''

        return {
          recipients,
        }
      }
    },
    handleDeleteBox: (effects, { target: { checked }}, recipient) => (state) => {
      const change = deleteChanges(recipient.email, checked)
      let { recipients } = state
      const { added, deleted, add, remove: _remove } = change
      if (added && deleted && isEmpty(add) && isEmpty(_remove)) {
        remove(recipients, r => r.email === recipient.email)
      } else {
        const r = find(recipients, r => r.email === recipient.email)
        r.disabled = checked
      }
      checked && effects.setSaveButton(true)
      recipients = [...recipients]
      return { recipients }
    },
    confirmUpdatedNotifications: () => {
      return {removalModalOpen: hasChanges()}
    },
    onRemovalModalClose: (effects, answer) => async () => {
      if (answer) {
        await effects.saveNotifications()
        return {
          removalModalOpen: false
        }
      }
    },
    saveNotifications: (effects) => async (_, props) => {
      try {
        const account = await getApi().updateNotifications(
          props.customerId,
          changes
        )
        await props.updateData(account)
        effects.setRecipients(account.preferences.emailNotifications)
        resetChanges()
        effects.setSaveButton(false)
        effects.notify('success', 'Notifications successfully saved')
      } catch (error) {
        await effects.handleError(error)
      }
    }
  }
})

/**
 * Main component of Additional email recipients view.
 * User can add recipients, and customizing their notifications preferences.
 * 
 * @component
 * @param {Array} recipients - A collection of recipients from account.preferences.emailNotifications
 */
const AdditionalEmailRecipients = ({
  recipients,
  effects,
  state,
}) => {

  return (
    <div style={{ margin: '20px' }}>
      <Row>
        <Col>
          <h3>Additional email recipients</h3>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <h6 className="text-muted">
          Here you can manage additional recipients and customize their notification preferences for documents
          and information we may send to you.
          </h6>
        </Col>
      </Row>
      <br />
      <Row>
        <Col md="8">
          <Card body>
            <ListGroup className="mb-2">
              {state.recipients.map((recipient) => (
                <ListGroupItem>
                  <ARP
                    recipient={recipient}
                    key={recipient.email}
                  />
                  <div className="float-right">
                    <Input
                      id={`${recipient.email}_id`}
                      name={`${recipient.email}_name`}
                      type="checkbox"
                      onChange={(event) => effects.handleDeleteBox(event, recipient)}
                      checked={recipient.disabled}
                    />
                    <FaTrash color="red" />
                  </div>
                </ListGroupItem>
              ))}
            </ListGroup>
            <br />
            <hr />
            <div className="row">
              <div className="col-md-6">
                <h3 className="text-muted">Add recipient</h3>
              </div>
            </div>
            <Form id="addRecipient" onSubmit={effects.addRecipient}>
              <Label for="email">Email address*</Label>
              <InputGroup>
                <Input
                  name="email"
                  required
                  type="email"
                  form="addRecipient"
                />
                <InputGroupAddon addonType="append">
                  <Button
                    type="submit"
                    color="primary"
                  >
                    Add&nbsp; <FaPlus />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </Form>
            <hr/>
            <Button
              className="float-right"
              color="success"
              disabled={state.saveDisabled}
              onClick={effects.confirmUpdatedNotifications}
            >
              Save
            </Button>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={state.removalModalOpen} toggle={effects.toggleModal}>
        <ModalHeader toggle={effects.toggleModal}>Confirmation</ModalHeader>
        <ModalBody>
          Are you sure you want to save this changes ?
          <ListGroup className="mt-2">
            {changes.map((change) => (
              <ComfirmationSummary key={change.email} change={change} baseRecipients={recipients} />
            ))}
          </ListGroup>
        </ModalBody>
        <ModalFooter>
          <Button outline onClick={effects.toggleModal}>
            Cancel
          </Button>
          <Button color="success" onClick={effects.onRemovalModalClose}>
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default withState(injectState(AdditionalEmailRecipients))
