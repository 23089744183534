import { FaInfoCircle } from 'react-icons/fa'
import Price from 'format-price'
import React, { Fragment } from 'react'
import { UncontrolledTooltip } from 'reactstrap'

const priceFilter = (input, currency = 'USD') =>
  Price.format('en-US', currency, input)

const getTooltip = (cart, currency) => {
  let tooltip = []
  cart
    .filter((cartItem) => cartItem.type === 'discount')
    .forEach((discount) => {
      tooltip.push(`${discount.name} ${priceFilter(discount.amount, currency)}`)
    })

  const cartItemTotal = cart.find((cartItem) => cartItem.type === 'total')
  if (cartItemTotal && cartItemTotal.tax && +cartItemTotal.tax.amount !== 0) {
    tooltip.push(`(Tax ${priceFilter(cartItemTotal.tax.amount, currency)})`)
  }

  return tooltip.join(', ')
}

const ManualOrderAmount = ({ order }) => {
  const cartItemTotal = order.cart.find((cartItem) => cartItem.type === 'total')
  if (!cartItemTotal) {
    return null
  }
  const amountInclTax = cartItemTotal.tax
    ? +cartItemTotal.amount + +cartItemTotal.tax.amount
    : +cartItemTotal.amount
  const tooltip = getTooltip(order.cart, order.currency)

  return (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      {tooltip.length > 0 && (
        <Fragment>
          <FaInfoCircle id={`due-detail${order.id}`} />
          <UncontrolledTooltip placement="top" target={`due-detail${order.id}`}>
            {tooltip}
          </UncontrolledTooltip>
        </Fragment>
      )}
      &nbsp;
      <span style={{ whiteSpace: 'nowrap' }}>
        {priceFilter(amountInclTax, order.currency)}
      </span>
      {order.paymentModel.type === 'subscription' && (
        <span className="alt-text">
          {order.paymentModel.year > 0 ? '/year' : '/month'}
        </span>
      )}
    </span>
  )
}

export default ManualOrderAmount
