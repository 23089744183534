import React from 'react'
import { Card, CardBody, CardTitle, Col, Form, Row } from 'reactstrap'
import { injectState, provideState } from 'reaclette'

import { getApi, generateCrossAuthToken } from '../../api'
import { isValidPassword } from '@vates/www-xo-utils'
import ActionButton from '../components/action-button'
import VatesLogo from '../../imgs/vates-logo.png'
import PasswordInput from '../password-input'

const ACCOUNT_ORIGINS = {
  account: 'account',
  vates: 'vates',
  xcpng: 'xcp-ng',
  xo: 'xen-orchestra',
}

const withState = provideState({
  initialState: () => ({
    password: '',
    loading: true,
  }),
  effects: {
    async initialize() {
      await this.props.decodeJwt(this.props.match.params.token)
      this.state.loading = false
    },
    async activateAccount(effects) {
      this.state.loading = true
      try {
        const account = await getApi().activateAccount(
          this.props.match.params.token,
          this.state.password
        )
        await effects.notify('success', 'Account activated')
        await effects.signIn(account.token)
        let landing = '/#/billingInfo'
        let activateEvent = 'account activated'

        if (account.origin) {
          const config = this.state.config
          const origins = {
            xcpng: 'xcpngUrl',
            xo: 'baseUrl',
          }
          const origin = config[origins[account.origin]]
          if (origin) {
            const cat = await generateCrossAuthToken(account.token)
            landing = `${origin}/#!/?cat=${encodeURIComponent(cat)}`
          }
          if (account.origin in ACCOUNT_ORIGINS) {
            activateEvent =
              ACCOUNT_ORIGINS[account.origin] + ' ' + activateEvent
          }
        }

        window._paq.push(['trackEvent', 'User', activateEvent])
        window.location.replace(landing)
      } catch (error) {
        effects.handleError(error)
      } finally {
        this.state.loading = false
      }
    },
    handlePasswordChange: (_, { target: { value } }) => ({ password: value }),
  },
})

const ActivateAccount = ({ effects, state }) => (
  <Row style={{ marginTop: '50px' }}>
    <Col sm={{ size: 4, offset: 4 }}>
      <Card body>
        <CardTitle>
          <div className="text-center">
            <img src={VatesLogo} alt="Vates" width="100" />
            <div className="card-title">Activate your account</div>
          </div>
        </CardTitle>
        <CardBody>
          <br />
          <Form id="activation-form">
            <PasswordInput
              placeholder="Choose your account password"
              password={state.password}
              onChange={effects.handlePasswordChange}
            />
            <ActionButton
              action={effects.activateAccount}
              block={true}
              color="success"
              disabled={!isValidPassword(state.password) || state.loading}
              form="activation-form"
              text="Activate"
            />
          </Form>
        </CardBody>
      </Card>
    </Col>
  </Row>
)

export default withState(injectState(ActivateAccount))
